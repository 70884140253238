self.addEventListener("push", (event) => {
    const notif = event.data.json().notification;
    event.waitUntil(
        self.registration.showNotification(notif.title, {
            body: notif.body,
            icon: notif.image,
            data: {
                url: notif.click_action
            }
        })
    );
});

self.addEventListener("notificationclick", (event) => {
    const url = event.notification.data.url || "https://blueswift.in/discount/BS200";

    event.waitUntil(
        clients.matchAll({ type: "window" }).then((clientList) => {
            for (let i = 0; i < clientList.length; i++) {
                let client = clientList[i];
                if (client.url === url && "focus" in client) {
                    return client.focus();
                }
            }
            if (clients.openWindow) {
                return clients.openWindow(url);
            }
        })
    );
});